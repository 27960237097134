<template>
  <router-view></router-view>
</template>

<script>
//Stop error resizeObserver
const debounce = (callback, delay) => {
  let tid;
  return function (...args) {
    const ctx = self;
    tid && clearTimeout(tid);
    tid = setTimeout(() => {
      callback.apply(ctx, args);
    }, delay);
  };
};

const _ = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ {
  constructor(callback) {
    callback = debounce(callback, 20);
    super(callback);
  }
};
export default {
  name: "App",
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
#app {
  width: 100%;
}
</style>
