import { ref } from "vue";
export const currentId = ref(null);

export function setToken(newToken) {
  clearToken();
  currentId.value = newToken;
  sessionStorage.setItem("userInfo", JSON.stringify(newToken));
}
export function clearToken() {
  sessionStorage.removeItem("userInfo");
  currentId.value = null;
}

export function getToken() {
  return JSON.parse(sessionStorage.getItem("userInfo"));
}

// // 存储视频
// export const currentVideo = ref(null);

// export function setVideo(item) {
//   clearVideo();
//   currentVideo.value = item;
//   localStorage.setItem("currentVideo", JSON.stringify(item));
// }
// export function clearVideo() {
//   currentVideo.value = null;
//   localStorage.removeItem("currentVideo");
// }
// export function getVideo() {
//   return JSON.parse(localStorage.getItem("currentVideo"));
// }
