import { createApp } from "vue";
import App from "./App.vue";
import router from "./router/routes";

import ElementPlus from "element-plus";
import zhCn from "element-plus/es/locale/lang/zh-cn";

import "element-plus/dist/index.css";
import "../src/style.css";
import "./rem";
import * as auth from "./store/auth";

const app = createApp(App);

app.use(ElementPlus, { locale: zhCn });
app.use(router);

// 路由守卫
router.beforeEach(function (to, from, next) {
  if (to.meta.needLogin) {
    if (auth.getToken()) {
      next();
    } else {
      next({
        path: "/",
      });
    }
  } else {
    next();
  }
});

app.mount("#app");
